var screen_sm = 768;
var app = new Vue({
    el: '#app',
    data: {
        easycare: {
            brand:          'fiat',
            model:          null,
            fuel:           null,
            duration:       null,
            distance:       null,
            price:          null,
            labels: {
                brand:      'Fiat',
                model:      '-',
                fuel:       '-',
                duration:   '-',
                distance:   '-',
                price:      '€ -,--'
            }
        },
        originalcare: {
            price: 169,
            twinair: false,
            twinair_price: 30
        },
        model: {
            year: null,
            code: null
        },
        basket: {
            articles: [],
        }
    },
    computed: {
        basket_sum: function() {
            var sum=0;
            for(var i=0; i<this.basket.articles.length; i++){
                if(typeof this.basket.articles[i].price=="number"){
                    sum+=this.basket.articles[i].price;
                }
            }
            return sum;
        },
        basket_contents: function() {
            var basket = '';
            for(var i=0; i<this.basket.articles.length; i++){
               basket += this.basket.articles[i].id;
               if (i<(this.basket.articles.length-1)) {
                basket += ',';
               }
            }

            //console.log(JSON.parse(this.basket.articles));
            //return JSON.stringify(this.basket.articles);
            return basket;
        },
        originalcare_price: function() {
            //console.log(tarief_original_care);
            if (this.originalcare.twinair == true) {
                return this.originalcare.price + this.originalcare.twinair_price;
            }
            return this.originalcare.price;
        },
    },
    methods: {
        add_to_basket: function(id,description,price) {
            if ( ! $('#product-'+id).hasClass('in-basket') ) {
                $('#product-'+id).addClass('in-basket');
                $('button', '#product-'+id).prop('disabled',true);
                app.basket.articles.push({
                    id: id,
                    description: description,
                    price: price
                });
                //console.table(app.basket.articles);
            }
        },
        remove_from_basket: function(article) {
            $('#product-'+article.id).removeClass('in-basket');
            $('button', '#product-'+article.id).prop('disabled',false);
            app.basket.articles.splice(app.basket.articles.indexOf(article), 1);
        },
    }
});
/*
$(function() {
    console.log('Ready!');
    $('#dealerlocator-confirm').prop('disabled', true);
    $('#dealerlocator-input').keyup(delay(function (e) {
        searchDealer($(this).val());
    }, 500));
});

function searchDealer(str) {
    console.log('Searching dealers XML for ' + str);
    url = 'http://dealerlocator.fiat.com/geocall/RestServlet?mkt=3122&brand=00&rad=50&serv=sales&dlr=1&func=finddealerxml&address=' + str;
}

function delay(callback, ms) {
    var timer = 0;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

*/
$(function() {
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scroll_to_target(target);
            }
        }
    });

    $('[data-href]').on('click',function(){
        href = $(this).data('href');
        console.log(href);
        window.location.href = href;
    });

    $('#js-easy-care-models label').on('click', function(){
        easycare_set_model($(this).data('model'));
    });
    //$('.dealers-autocomplete').selectToAutocomplete();
    $('.dealers-autocomplete').chosen({
        placeholder_text_single: 'Zoek op plaats of naam',
        no_results_text: 'Probeer het <a href="#dealer-addressbook" data-toggle="modal" data-target="#dealer-addressbook">adresboek</a>. Niets gevonden met'
    });

    $('.link-social').click(function(e) {
        e.preventDefault();
        window.open($(this).attr('href'), 'link-social', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
    });
    $('#js-model-year label').on('click', function(){
        model_set_year($(this).data('year'));
    });
    $('#js-model-codes label').on('click', function(){
        model_set_code($(this).data('code'));
    });

    set_sidebar_affix();

    if (  $('main > section:last-child').hasClass('bg-gray') ) {
        $('#app > footer').addClass('bg-white');
    }

    if (typeof originalcare_twinair !== 'undefined') {
        app.originalcare.twinair = originalcare_twinair;
    }

    $('.js-select-dealer').on('click',function(e){
        e.preventDefault();
        val = $(this).data('dealer');
        target = $(this).data('target');
        modal = $(this).data('modal');
        //console.log(target);
        $(target).val(val);
        $(target).trigger("chosen:updated");
        $(modal).modal('hide');
    })

    if (typeof tarief_original_care !== 'undefined') {
        app.originalcare.price = tarief_original_care;
    }
    if (typeof has_twinair !== 'undefined') {
        app.originalcare.twinair = has_twinair;
    }


});

$(document).ready(function(){
    var switchery_elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
    switchery_elems.forEach(function(html) {
      var switchery = new Switchery(html);
    });
});




$( window ).resize(function() {
    set_sidebar_affix();
});

function scroll_to_target(target) {
    $('html, body').animate({
        scrollTop: (target.offset().top - parseInt($('body').css('padding-top')))
    }, 500);
    return false;
}

function set_sidebar_affix() {
    if ($(window).width() >= screen_sm) {
        //console.log('set affix');
        $('.sidebar')
            .width($('.sidebar').width())
            .css('top',$('.navbar').outerHeight(true))
            .affix(
                {
                    offset: {
                        top: function () {
                            var position = $('.sidebar').offset();
                            //console.log(position.top);
                            return (this.top = position.top - $('.navbar').outerHeight(true));
                        },
                        bottom: function () {
                            return (this.bottom = $('#app > footer').outerHeight(true))
                        }
                    }
                }
            );
    } else {
        //console.log('unset affix');
        $(window).off('.affix');
        $('.sidebar')
            .width('auto')
            .removeClass("affix affix-top affix-bottom")
            .removeData("bs.affix");
    }
}

function easycare_set_model(model) {
    //console.log('easycare_set_model('+model+')');
    easycare_unset_fuel();
    app.easycare.model = model;
    app.easycare.labels.model = easycare_models[model].model;
    easycare_show_fuel();
}
function easycare_unset_model() {
    //console.log('easycare_unset_model');
    app.easycare.model = null;
    $('#js-easy-care-model').addClass('hidden');
    easycare_unset_fuel();
}

function easycare_show_fuel() {
    //console.log('easycare_show_fuel');
    if (app_environment == 'production') {
        ga('send', 'pageview', '/easycare/fuel');
    }

    if (app.easycare.model != null) {
        var html = '';
        $('#js-easy-care-fuel .mega-radio-group').html('');
        $.each(easycare_models[app.easycare.model].versions, function(index, value){
            html += '<input type="radio" name="fuel" value="'+index+'" id="input-fuel-'+index+'" class="mega-radio">';
            html += '<label for="input-fuel-'+index+'" class="mega-radio-label" data-fuel="'+index+'">';
            html += '<span class="label">'+value.fuel+'</span>';
            html += '<span class="custom-input"></span>';
            html += '</label>';
        });
        $('#js-easy-care-fuel .mega-radio-group').append(html);
        $('#js-easy-care-fuel').removeClass('hidden');
        $('#js-easy-care-fuel label').on('click', function(e){
            easycare_set_fuel($(this).data('fuel'));
        });
        scroll_to_target($('#js-easy-care-fuel'));
    }
}

function easycare_set_fuel(fuel) {
    //console.log('easycare_set_fuel('+fuel+')');
    easycare_unset_duration();
    app.easycare.fuel = fuel;
    app.easycare.labels.fuel = easycare_models[app.easycare.model].versions[fuel].fuel;
    easycare_show_duration();
}

function easycare_unset_fuel() {
    //console.log('easycare_unset_fuel');
    app.easycare.fuel = null;
    $('#js-easy-care-fuel').addClass('hidden');
    easycare_unset_duration()
}

function easycare_show_duration() {
    //console.log('easycare_show_duration');
    if (app_environment == 'production') {
        ga('send', 'pageview', '/easycare/duration');
    }

    if (app.easycare.model != null && app.easycare.fuel != null) {
        $('#js-easy-care-duration label').on('click', function(){
            easycare_set_duration($(this).data('duration'));
        });
        $('#js-easy-care-duration').removeClass('hidden');
        scroll_to_target($('#js-easy-care-duration'));
    }
}
function easycare_set_duration(duration) {
    //console.log('easycare_set_duration('+duration+')');
    easycare_unset_distance();
    app.easycare.duration = duration;
    app.easycare.labels.duration = duration + ' jaar';
    easycare_show_distance();
}
function easycare_unset_duration() {
    //console.log('easycare_unset_duration');
    app.easycare.duration = null;
    $('#js-easy-care-duration').addClass('hidden');
    easycare_unset_distance()
}
function easycare_show_distance() {
    //console.log('easycare_show_distance');
    if (app_environment == 'production') {
        ga('send', 'pageview', '/easycare/distance');
    }

    if (app.easycare.model != null && app.easycare.fuel != null && app.easycare.duration != null) {
        $('#js-easy-care-distance .mega-radio-group').html('');
        html = '';
        $.each(easycare_models[app.easycare.model].versions[app.easycare.fuel].pricing[app.easycare.duration], function(index, value){
            //console.log(index);
            //console.log(value);
            price = value;
            if (price != null) {
                html += '<input type="radio" name="distance" value="'+index+'" id="input-distance-'+index+'" class="mega-radio">';
                html += '<label for="input-distance-'+index+'" class="mega-radio-label" data-distance="'+index+'">';
                html += '<span class="label">'+parseInt(index).formatNumber(0)+' km</span>';
                html += '<span class="custom-input"></span>';
                html += '</label>';
            }
        });
        $('#js-easy-care-distance .mega-radio-group').append(html);
        $('#js-easy-care-distance').removeClass('hidden');
        $('#js-easy-care-distance label').on('click', function(){
            easycare_set_distance($(this).data('distance'));
        });
        scroll_to_target($('#js-easy-care-distance'));

    }
}
function easycare_set_distance(distance) {
    //console.log('easycare_set_distance('+distance+')');
    easycare_unset_result();
    app.easycare.distance = distance;
    app.easycare.labels.distance = distance.formatNumber(0) + ' km';
    easycare_show_result();
}
function easycare_unset_distance() {
    //console.log('easycare_unset_distance');
    app.easycare.distance = null;
    $('#js-easy-care-distance').addClass('hidden');
    easycare_unset_result()
}

function easycare_show_result() {
    //console.log('easycare_show_result');
    if (app_environment == 'production') {
        ga('send', 'pageview', '/easycare/result');
    }
    if (app.easycare.model != null && app.easycare.fuel != null && app.easycare.duration != null && app.easycare.distance != null) {
        price = easycare_models[app.easycare.model].versions[app.easycare.fuel].pricing[app.easycare.duration][app.easycare.distance];
        app.easycare.price = price;
        app.easycare.labels.price = '€ '+ price.formatNumber();
        $('#js-easycare-submit').attr('disabled',false);
        scroll_to_target($('#js-easy-care-result'));
    }
}
function easycare_unset_result() {
    //console.log('easycare_unset_result');
    app.easycare.price = null;
    $('#js-easycare-submit').attr('disabled',true);
}

function model_set_year(year) {
    //console.log('model_set_year('+year+')');
    model_unset_year();
    app.model.year = year;
    model_show_codes(year);
}

function model_unset_year() {
    //console.log('model_unset_year');
    app.model.year = null;
    $('input[name="code"]').prop('checked',false);
}

function model_show_codes(year) {
    if (app_environment == 'production') {
        ga('send', 'pageview', '/model/version');
    }
    $('.versions-model').addClass('hidden');
    $('.versions-model-'+year).removeClass('hidden');
    $('.model-versions').removeClass('hidden');
    scroll_to_target($('#js-model-codes'));
}
function model_set_code(code) {
    //console.log('model_set_code('+code+')');
    model_unset_code();
    app.model.code = code;
    scroll_to_target($('#js-model-submit'));
}

function model_unset_code() {
    //console.log('model_unset_code');
    app.model.code = null;
}

Number.prototype.formatNumber = function(c, d, t){
    var n = this,
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "," : d,
    t = t == undefined ? "." : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};
